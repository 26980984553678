import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms"
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-consulting-development',
  templateUrl: './consulting-development.component.html',
  styleUrls: ['./consulting-development.component.less']
})
export class ConsultingDevelopmentComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) { }


  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('咨询&开发-智纹科技');
  }

  public userAgent: string;

  public contactForm: FormGroup;

  public contactContent: any = [
    {
      text: '工业装备智能化',
      imgUrl: "/assets/img/stexture-contact-content1.png",
      detail: "适应领域特定需求，以人工智能技术为基础，解决装备、技术及数据融合问题，实现AI赋能工业生产，助力企业从传统工业到数字工业的转型"
    },
    {
      text: '工业自动化',
      imgUrl: "/assets/img/stexture-contact-content2.png",
      detail: "依托明寻实验室在物联网、人工智能、机器视觉领域的技术探索与产业化能力，深入企业场景，提供企业自动化过程的解决方案和技术实现"
    },
    {
      text: '工业视觉检测',
      imgUrl: "/assets/img/stexture-contact-content3.png",
      detail: "从元件物料识别与分拣开始，到生产线监控及产品缺陷检测的全流程自动化视觉系统咨询以及软硬件开发，帮助企业消除错误、降本增效"
    }
  ]

  ngOnInit() {
    this.dynamicSetTitle();
  }


}
