import { 
  Component, 
  ElementRef, // 元素指向
  HostBinding, 
  OnDestroy, 
  OnInit 
} from '@angular/core';

@Component({
  selector: 'app-tooltip', // 标识符，表明我这个组件叫做啥，这里是 app-tooltip
  templateUrl: './tooltip.component.html', // 本组件的骨架
  styleUrls: ['./tooltip.component.scss'] // 本组件的私有样式
})
export class TooltipComponent implements OnInit {

  public data: any; // 在 directive 上赋值
  private displayTimeOut:any;

  // 组件本身 host 绑定相关的装饰器
  @HostBinding('style.top')  hostStyleTop!: string;
  @HostBinding('style.left') hostStyleLeft!: string;
  @HostBinding('style.opacity') hostStyleOpacity!: string;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.hostStyleTop = this.data.elementPosition.bottom + 'px';

    if(this.displayTimeOut) {
      clearTimeout(this.displayTimeOut)
    }

    this.displayTimeOut = setTimeout((_: any) => {
      // 这里计算 tooltip 距离左侧的距离，这里计算公式是：tooltip.left + 目标元素的.width - (tooltip.width/2)
      this.hostStyleLeft = this.data.elementPosition.left + this.data.element.clientWidth / 2 - this.elementRef.nativeElement.clientWidth / 2 + 'px'
      this.hostStyleOpacity = '1';
      this.hostStyleTop = this.data.elementPosition.bottom + 10 + 'px'
    }, 50)
  }
  
  
  // 组件销毁
  ngOnDestroy() {
    // 组件销毁后，清除定时器，防止内存泄露
    if(this.displayTimeOut) {
      clearTimeout(this.displayTimeOut)
    }
  }
}