import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-all',
  templateUrl: './header-all.component.html',
  styleUrls: ['./header-all.component.less']
})
export class HeaderAllComponent implements OnInit {

  constructor() { }

  @Input() public title: any; //标题
  @Input() public desc: any; //描述

  ngOnInit(): void {
  }

}
