import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { EChartOption } from 'echarts';
// import { IntersectionObserver } from 'intersection-observer';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-anti-fake',
  templateUrl: './anti-fake.component.html',
  styleUrls: ['./anti-fake.component.less']
})
export class AntiFakeComponent implements OnInit {

  constructor(
    private meta: Meta,
    private titleService: Title,
    private myActivatedRoute: ActivatedRoute

  ) { }

  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('服务支持-智纹科技');
    // this.meta.addTag({ name: 'keywords', content: '隐形水印,百货基因' });
    // this.meta.addTag({ name: 'description', content: '百货基因的优势包括完全隐形防伪和不可攻破性；百货基因的隐形水印与包装原图像的像素差小于1%，远低于人眼所能察觉的最小范围；在不改变现有包装生产工艺的前提下，可以确保嵌入百货基因隐形水印商品包装的唯一性，杜绝非法的二次复制或修改。服务热线：4000861108。' });
  }


  public stextureContent: any = [
    {
      title: '服务内容',
      anchorId: 'stextureSupport1',
      content: [
        {
          text: '售前服务',
          imgUrl: "/assets/img/stexture-support-content1.webp",
          detail: "根据您的业务需求，我们的售前工程师将为您制定最为贴合的产品应用落地方案"
        },
        {
          text: '技术支持',
          imgUrl: "/assets/img/stexture-support-content2.webp",
          detail: "技术专家始终在您身边，为您提供快速响应的咨询服务，全面保障您的业务运转"
        },
        {
          text: '产品培训',
          imgUrl: "/assets/img/stexture-support-content3.webp",
          detail: "我们专业的培训经理将为您提供产品方案的使用及维护培训"
        }
      ]
    },
    {
      title: '技术保障',
      anchorId: 'stextureSupport2',
      content: [
        {
          text: '技术支持',
          imgUrl: "/assets/img/stexture-support-content4.webp",
          detail: "提供全天快速响应的技术支持服务，技术专家还可通过视频连线帮您排除设备故障"
        },
        {
          text: '现场支持',
          imgUrl: "/assets/img/stexture-support-content5.webp",
          detail: "提供现场实施支持，帮助您顺利完成产品方案的安装与调试，提供产品的周期性巡检"
        },
      ]
    },

  ]

  public descContent: any = [
    {
      title: '基础服务',
      content: [
        {
          text: "服务热线",
          hasSupported: true,
        },
        {
          text: "电话/在线支持",
          hasSupported: true,
        },
        {
          text: "视频支持",
          hasSupported: false,
        },
        {
          text: "现场支持",
          hasSupported: false,
        },
        {
          text: "备件",
          hasSupported: false,
        }
      ]
    },
    {
      title: '进阶服务',
      content: [
        {
          text: "服务热线",
          hasSupported: true,
        },
        {
          text: "电话/在线支持",
          hasSupported: true,
        },
        {
          text: "视频支持",
          hasSupported: true,
        },
        {
          text: "现场支持",
          hasSupported: false,
        },
        {
          text: "备件",
          hasSupported: false,
        }
      ]
    },
    {
      title: '无忧服务',
      content: [
        {
          text: "服务热线",
          hasSupported: true,
        },
        {
          text: "电话/在线支持",
          hasSupported: true,
        },
        {
          text: "视频支持",
          hasSupported: true,
        },
        {
          text: "现场支持",
          hasSupported: true,
        },
        {
          text: "备件",
          hasSupported: true,
        }
      ]
    }
  ]


  public goLocalAnchor(id: string) {
    let anchor = document.getElementById(id);
    anchor.scrollIntoView()
  }

  ngOnInit(): void {
    this.dynamicSetTitle();
  }

  ngAfterViewInit(): void {
    this.myActivatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        let anchor = document.getElementById(params.id);
          anchor.scrollIntoView()
      }
    })

  }



}
