import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  @Output() goLocalAnchor = new EventEmitter<any>();


  constructor(private _router: Router) { }

  // 跳转到锚点
  public goAnchor(router: string, id: string) {
    const routerParams: NavigationExtras = {
      queryParams: {
        id,
      }
    };
    if (!location.href.includes(router)) {
      this._router.navigate([router], routerParams)
    } else {
      this.goLocalAnchor.emit(id)
    }
  }


  public footerContent: Array<any> = [
    {
      title: '百货基因',
      desc: '百货基因是突破性的「数学」防伪技术，给客户带来兼顾高安全与低成本的高科技防伪选择。'
    }
  ]

  public footerList: Array<any> = [
    {
      title: '能力与方案',
      subConternt: [
        {
          title: '核心能力',
          routerUrl: '/core',
        },
        {
          title: '黄金首饰解决方案',
          routerUrl: '/product',
        },
        {
          title: '钻石行业解决方案',
          routerUrl: '/diamond',
        },
      ]
    },
    {
      title: '服务与支持',
      subConternt: [
        {
          title: '咨询与开发',
          routerUrl: '/consultation',
        },
        {
          title: '服务支持',
          routerUrl: '/support',
        },
      ]
    },
    {
      title: '公司',
      subConternt: [
        {
          title: '关于智纹',
          routerUrl: '/about',
        },
        {
          title: '联系智纹',
          routerUrl: '/contact',
        },
        // {
        //   title: '公司新闻',
        //   subUrl: 'https://url.cn/X6IXArLL?_type=wpa&qidian=true'
        // }
      ]
    },
  ]

  ngOnInit(): void {
  }

}
